import React from 'react';
import MyDocument from '../components/MyDocument';
import { useLocation } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';

const PrintPage = () => {
  const location = useLocation();
  const { cabinData } = location.state || {};

  if (!cabinData) return <div>Нет данных для печати</div>;

function getState(){
  console.log('cabinData = ', cabinData);
  
}

  return (
    <>
{/*       <div>PrintPage</div>
      <button onClick={getState}>getState</button> */}
      <PDFViewer width="100%" height="1300">
        <MyDocument cabinData={cabinData} />
      </PDFViewer>
    </>
  );
};

export default PrintPage;
