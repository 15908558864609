import logo from './logo.svg';
import './App.css';
import MainPage from './pages/MainPage';
import PrintPage from './pages/PrintPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (

    <Routes>

      <Route path="/" element={<MainPage />} />
      <Route path="/print" element={<PrintPage />} />

    </Routes>


  );
}

export default App;
