import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: { padding: 20, fontFamily: 'Roboto' },
  section: { margin: 10, padding: 10 },
  header: { fontSize: 20, marginBottom: 10 },
  image: { width: 350, height: 'auto', marginVertical: 10 },
  labelText: { opacity: 0.7 }, // Более бледный текст
  dimensionsTitle: { marginTop: 10, opacity: 0.7 }, // Отступ и бледный цвет для "Размеры"
  dimensionItem: { marginLeft: 10, marginVertical: 2 }, // Отступ для элементов списка "Размеры"
  priceContainer: { marginTop: 10 }, // Отступ сверху для "Цена"
  priceText: { fontSize: 24, fontWeight: 'bold' }, // Увеличенный шрифт для цены
});

// Регистрация шрифта с использованием абсолютного пути
Font.register({
  family: 'Roboto',
  src: '/assets/fonts/Roboto/Roboto-Regular.ttf',
});

const MyDocument = ({ cabinData }) => {
  const imageUrl = `${process.env.REACT_APP_URL}/${cabinData.photo.replace(/\\/g, '/')}`;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          {/* Блок информации о стекле и фурнитуре */}
          <Text>
            <Text style={styles.labelText}>Стекло: </Text>
            <Text>{cabinData.glassName}</Text>
          </Text>
          <Text>
            <Text style={styles.labelText}>Фурнитура: </Text>
            <Text>{cabinData.hardware}</Text>
          </Text>

          {/* Блок "Размеры" с отступом */}
          <Text style={styles.dimensionsTitle}>Размеры:</Text>
          <View>
            {cabinData.dimensions.map((item) => (
              <Text key={item.dimension_id} style={styles.dimensionItem}>
                {item.title}: {item.dimension} мм
              </Text>
            ))}
            {/* Добавляем высоту как часть размеров */}
            <Text style={styles.dimensionItem}>
              Высота: {cabinData.height} мм
            </Text>
          </View>

          {/* Блок для отображения цены с увеличенным шрифтом */}
          <View style={styles.priceContainer}>
            <Text>
              <Text style={styles.labelText}>Цена: </Text>
              <Text style={styles.priceText}>{cabinData.finalPrice} руб.</Text>
            </Text>
          </View>

          {/* Изображение */}
          {cabinData.photo && <Image src={imageUrl} style={styles.image} />}
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
